<!--  -->
<template>
  <div class="VideoAll Card-b-c" v-loading="loading" element-loading-text="数据量过大，加载中...">
    <Titles Titles="监控视频" />
    <div class="video_main flex flex-wrap">
      <div class="btn-arrow">
        <i class="el-icon-arrow-left" :style="{ 'pointer-events': page > 1 ? '' : 'none', opacity: page > 1 ? '1' : '0'}" @click="nextpage(page - 1)"></i>
        <i class="el-icon-arrow-right" :style="{ 'pointer-events': page < total / 2 ? '' : 'none', opacity: page < total / 2 ? '1' : '0'}" @click="nextpage(page + 1)"></i>
      </div>
      <div
        class="flex flex-a-c flex-j-c"
        style="width:50%;height:100%"
        v-if="total <= 0"
      >
        <img
          src="../../assets/images/index/video.png"
          style="width:calc(330/1920*100vw); height:calc(329/1080*100vh)"
        />
      </div>
      <div class="capture_box" v-else>
        <BtnVideo
          v-if="videoListA.videoType === 0"
          :accessToken="videoListA.accessToken"
          :channelNo="videoListA.channelNo"
          :deviceSerial="videoListA.videoModel"
        />
        <div
          id="videoListA"
          style="width: 100%; height: 100%;"
        ></div>
      </div>
      <div
        class="flex flex-a-c flex-j-c"
        style="width:50%;height:100%"
        v-if="videoListL < 2"
      >
        <img
          src="../../assets/images/index/video.png"
          style="width:calc(330/1920*100vw); height:calc(329/1080*100vh)"
        />
      </div>
      <div class="capture_box" v-else>
        <BtnVideo
          v-if="videoListB.videoType === 0"
          :accessToken="videoListB.accessToken"
          :channelNo="videoListB.channelNo"
          :deviceSerial="videoListB.videoModel"
        />
        <div
          id="videoListB"
          style="width: 100%; height: 100%;"
        ></div>
      </div>
      <!-- <div
        class="flex flex-a-c flex-j-c"
        style="width:100%;height:calc(800/1080*100vh)"
        v-if="videoList.length == '0'"
      >
        <img
          src="../../assets/images/index/video.png"
          style="width:calc(330/1920*100vw); height:calc(329/1080*100vh)"
        />
      </div> -->
      <!-- <div class="capture_box" v-for="item in videoList" :key="item.id" v-else>
        <BtnVideo
          v-if="item.videoType === 0"
          :accessToken="item.accessToken"
          :channelNo="item.channelNo"
          :deviceSerial="item.videoModel"
        />
        <div
          :id="`videoItem-${item.id}`"
          style="width: 100%; height: 100%;"
        ></div>
      </div> -->
    </div>

    <!-- <div class="pagination">
      <el-pagination
        :hide-on-single-page="true"
        background
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @prev-click="nextpage"
        @next-click="nextpage"
        @current-change="nextpage"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import Titles from "../../components/Titles";
import { getWaterLevel } from "../../api/WaterLevel";
import BtnVideo from "../../components/BtnVideo/index.vue";

export default {
  components: { Titles, BtnVideo },
  data() {
    return {
      siteId: "",
      siteWaterVo: {},
      isMains: "",
      page: 1,
      size: 2,
      pageSize: 0,
      loading: false,
      total: 0,
      videoUrl: [], // 视频地址
      videoListL: 0,
      videoListA: {}, // 视频列表
      videoListB: {}, // 视频列表
      tableData: []
    };
  },
  computed: {},
  watch: {
    // 监听路由变化
    $route: "myroute"
  },
  methods: {
    myroute() {
      // 重新赋值
      this.siteId = this.$route.params.siteId;
      // this.getWaterList();
      this.getVideo();
    },
    // 获取水位站基本信息
    getWaterList() {
      getWaterLevel({ id: this.siteId }).then(res => {
        // console.log("getWaterList", res);
        this.siteWaterVo = res.data.siteWaterVo;
        this.isMains = res.data.isMains;
      });
    },
    // 获取水位站视频
    getVideo() {
      this.loading = true;
      let url;
      if (this.siteId === "all") {
        url = `/video/findAllByPage?page=${this.page}&size=${this.size}`;
      } else {
        url = `/video/overview/${this.page}/${this.size}?siteId=${this.siteId}`;
      }
      this.$get(url, {}).then(res => {
        // console.log("video", res);
        this.videoListL = res.data.list.length
        this.videoListA = res.data.list[0];
        this.videoListB = res.data.list[1];
        this.total = res.data.total;
        this.pageSize = res.data.pageSize;
        setTimeout(() => {
          const videoA = document.querySelector('#videoListA');
          const videoB = document.querySelector('#videoListB');
          if (videoA) {
            videoA.innerHTML = "";
            new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: 'videoListA',
              accessToken: this.videoListA.accessToken,
              url: this.videoListA.videoAddress,
              template: "simple",
              audio: 0
            });
          }
          if (videoB) {
            videoB.innerHTML = "";
            new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: 'videoListB',
              accessToken: this.videoListB.accessToken,
              url: this.videoListB.videoAddress,
              template: "simple",
              audio: 0
            });
          }
        }, 500);
        this.loading = false;
      });
    },
    // 翻页
    nextpage(value) {
      // console.log(value);
      this.page = value;
      this.getVideo();
    }
  },

  created() {
    this.myroute();
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.VideoAll {
  width: calc(1570 / 1920 * 100vw);
  height: calc(960 / 1080 * 100vh);
  .video_main {
    position: relative;
    height: calc(100% - calc(80 / 1080 * 100vh));
    padding: 0 calc(30 / 1080 * 100vh);
    padding-top: calc(30 / 1080 * 100vh);
    box-sizing: border-box;
    display: flex;
    // justify-content: space-evenly;
    flex-wrap: wrap;
    .btn-arrow{
      position: absolute;
      top: 50%;
      display: flex;
      justify-content: space-between;
      width: calc(100% - calc(100 / 1920 * 100vw));
      padding: 0 calc(20 / 1920 * 100vw);
      z-index: 99;
      >i{
        background: #fff;
        color: #000;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
        transform: translateY(-50%);
        &:active{
          transform: translateY(-37.5%);
        }
      }
    }
    .capture_box {
      width: calc(50% - calc(7.5 / 1080 * 100vh));
      height: 100%;
      position: relative;
      background: #000;
      margin-bottom: calc(20 / 1080 * 100vh);
      &+.capture_box{
        margin-left: calc(15 / 1080 * 100vh);
      }
    }
  }
}
.el-pagination {
  padding: calc(35 / 1080 * 100vh);
}
</style>
